import {elementReady} from "../ReadyListener";

elementReady(async  (root) => {

    const modules = root.findWithRoot('.contact-autofill');

    modules?.each((i,el) => {
        if (!el.setupDone) {
            setupModule(el);
            el.setupDone = true;
        }
    })
})

const setupModule = (el) => {
    
    const link = el.querySelector("a");
    const form = document.querySelector("#contact-form");

    if(form) {
        link.addEventListener('click', function(event) {
            event.preventDefault(); // Prevent the default href action
            event.stopPropagation();
            onLinkClick(el, form);
        });
    } else {
        const oldUrl = new URL(link.href);
        link.href = `${el.dataset.contactPage}${oldUrl.search}${oldUrl.hash}`;
    }
    
}

const onLinkClick = (el, form) => {
    const elementPosition = form.getBoundingClientRect().top + window.scrollY - 320;

    const inputSubject = form.querySelector("input[name='subject']");
    const inputMessage = form.querySelector("textarea[name='message']");

    if(inputSubject) inputSubject.value = el.dataset.subject;
    if(inputMessage) inputMessage.value = el.dataset.message.replaceAll("\\n", '\n');

    window.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
    });
}