import $ from "jquery";

import {elementReady} from "../ReadyListener";

// Slider with slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./component.slider.scss";


elementReady(async  (root) => {
    const accordions = root.findWithRoot('.c-slider');
    const references = root.findWithRoot('.reference-slider');

    if(accordions?.length > 0 || references?.length > 0) {
        await import('slick-carousel/slick/slick');
        accordions?.each((i,el) => {
            if (!el.setupDone) {
                setupQuoteSlider(el);
                el.setupDone = true;
            }
        })
        references?.each((i,el) => {
            if (!el.setupDone) {
                setupReferenceSlider(el);
                el.setupDone = true;
            }
        })
    }
})

const setupQuoteSlider = (el) => {
    const $comp = $(el);

    $comp.on('init', function(event, slick){
        $comp.removeClass("opacity-0")
    });

    $comp.slick();
}

const setupReferenceSlider = (el) => {
    const $comp = $(el);

    $comp.on('init', function(event, slick){
        $comp.removeClass("opacity-0")
    });

    $comp.slick({
        infinite: true,
        slidesToShow: $comp.data("slides") ?? 8,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        draggable: false,
        pauseOnHover: false,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: Math.min($comp.data("slides") ?? 8, 4),
              }
            }
        ]
    });
}
