import {elementReady} from "../ReadyListener";

elementReady(async  (root) => {

    const modules = root.findWithRoot('.saas-packages');

    modules?.each((i,el) => {
        if (!el.setupDone) {
            setupModule(el);
            el.setupDone = true;
        }
    })
})

const setupModule = (el) => {

    let monthlyLabels = el.querySelectorAll(".label-monthly");
    let yearlyLabels = el.querySelectorAll(".label-yearly");

    const doTransition = (checked, el) => {
        if(checked) {
            el.style.opacity = "0";
            el.style.display = "block";
            setTimeout(() => {
                el.style.opacity = "1";
            }, 50)
        } else {
            el.style.display = "none";
        }
    }

    el.querySelector(".radio-monthly").addEventListener("change", (event) => {
        const checked = event.currentTarget.checked;
        monthlyLabels.forEach(el => {
            doTransition(checked, el);
        });
        yearlyLabels.forEach(el => {
            doTransition(!checked, el);
        });
    });
    el.querySelector(".radio-yearly").addEventListener("change", (event) => {
        const checked = event.currentTarget.checked;
        monthlyLabels.forEach(el => {
            doTransition(!checked, el);
        });
        yearlyLabels.forEach(el => {
            doTransition(checked, el);
        });
    });
}