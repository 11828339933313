import $ from 'jquery';
import Cookies from 'js-cookie'

export const setupCSRF = function(cookie, token) {

  Cookies.set(cookie, token, { secure: true, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
}

export const postJSON = function(url, data, success, dataType) {
  if (typeof data != 'string') {
    data = JSON.stringify(data);
  }
  $.ajax({
    url : url,
    type: "post",
    data: data,
    dataType: dataType || "json",
    contentType: "application/json",
    success: success
  });
}


export const postFiles = function({url, files, filename_suffixes, success, error, progress, postFileField='file'}) {

  let data = new FormData();
  $.each(files, function(i, file) {
    let name = file.name;
    if(filename_suffixes && filename_suffixes.get(file)) {
      name = name + filename_suffixes.get(file);
    }
    data.append(postFileField, file, name);
  });

  $.ajax({
    url : url,
    type: "POST",
    data: data,
    cache: false,
    contentType: false,
    processData: false,
    success: success,
    error: error,
    xhr: function() {
      var myXhr = $.ajaxSettings.xhr();
      if(myXhr.upload){
        myXhr.upload.addEventListener('progress', (e) => {
          if(e.lengthComputable){
            var max = e.total;
            var current = e.loaded;

            var Percentage = (current * 100)/max;
            //console.log(Percentage);

            if(Percentage >= 100)
            {
              //console.log("finished")
            }
          }
        }, false);
      }
      return myXhr;
    },
  });
}