export const executeAfterLCP = function(callback, timeout=3000) {
    let isLCPOccurred = false;

    // Check if LCP has already occurred
    const lcpEntries = performance.getEntriesByType('largest-contentful-paint');
    if (lcpEntries.length > 0) {
        isLCPOccurred = true;
        callback(); // Execute immediately if LCP has already occurred
        return;
    }

    // Set up PerformanceObserver for future LCP events, if it hasn’t occurred yet
    if ('PerformanceObserver' in window) {
        const observer = new PerformanceObserver((entries) => {
            for (const entry of entries.getEntries()) {
                if (entry.name === 'largest-contentful-paint') {
                    isLCPOccurred = true;
                    callback(); // Execute callback when LCP is detected
                    observer.disconnect(); // Stop observing
                    return;
                }
            }
        });

        // Start observing LCP
        observer.observe({ type: 'largest-contentful-paint', buffered: true });
    }

    // Set a timeout as a fallback
    setTimeout(() => {
        if (!isLCPOccurred) {
            callback(); // Execute callback after timeout if LCP hasn’t occurred
        }
    }, timeout);
}