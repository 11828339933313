import $ from "jquery";
import {elementReady} from "../ReadyListener";
import {animateIconDown, animateIconUp} from "../helpers/animateIcon";

elementReady((root) => {
    const languagePickers = root.findWithRoot('.c-language-picker')
    languagePickers.each((i, el) => {
        if (!el.setupDone) {
            setupLanguagePicker(el);
            el.setupDone = true;
        }
    })
})

const setupLanguagePicker = (el) => {
    const $comp = $(el);
    const languagePickerHead = $comp.find('.c-language-picker__head');
    const languagePickerOptions = $comp.find('.c-language-picker__options')
    const hiddenInput = $comp.find(".hidden-input")
    const selectedOption = $comp.find('.c-language-picker__selected')
    let initialLabel = selectedOption.text().trim();
    const initialValue = hiddenInput.val();
    const backgroundColorPicked = "bg-actidoo-gray-100"
    const fontColorPicked = "text-actidoo-red-primary"
    const pointerPicked = "pointer-events-none cursor-none"

    const canBeEmpty = ($(languagePickerHead).data('allowed-empty') === 'true' || $(languagePickerHead).data('allowed-empty') === 'True');

    const initialOption = document.createElement('div');

    if (!initialLabel && canBeEmpty) {
        initialLabel = "--";
        $(initialOption).addClass("p-3 border-b border-actidoo-gray-300 c-language-picker__option last:border-b-0");
    }

    $(initialOption).text(initialLabel);

    if (initialValue) {
        $(initialOption).attr("data-option-value", initialValue);
        $(initialOption).addClass("p-3 border-b border-actidoo-gray-300 c-language-picker__option last:border-b-0 ");
    }

    $(languagePickerOptions).find(".c-language-picker__option").each((index, element) => {
        if (selectedOption.text().trim() === $(element).text().trim()) {
            $(element).addClass(backgroundColorPicked)
            $(element).addClass(fontColorPicked)
            $(element).addClass(pointerPicked)
        }
    })

    $(document).click(function (event) {
        if (!$(event.target).is(languagePickerHead) && !$(event.target).is(languagePickerOptions)) {
            closePicker(languagePickerOptions, languagePickerHead)
        }
    });
    $(languagePickerHead).click((ev) => {
        togglePicker(languagePickerOptions, languagePickerHead);
    })
    $(languagePickerOptions).find(".c-language-picker__option").each((index, element) => {
            $(element).click((ev) => {
                const inputVal = hiddenInput.val();
                const optionVal = $(element).data("option-value");
                if (inputVal !== optionVal) {
                    $(languagePickerOptions).find('.c-language-picker__option').each((i, element) => {
                        $(element).removeClass(backgroundColorPicked);
                    })

                    if (!$(element).data("option-value")) {
                        setPickerHeaderText("", $(languagePickerHead).find(".c-language-picker__selected"))
                    } else {
                        setPickerHeaderText(element.innerText, $(languagePickerHead).find(".c-language-picker__selected"))
                        $(element).addClass(backgroundColorPicked)
                        $(element).addClass(fontColorPicked)
                        $(element).addClass(pointerPicked)
                    }
                    togglePicker(languagePickerOptions, languagePickerHead);
                    hiddenInput.val($(element).data("option-value"))
                    onChangeLanguage($(element));
                } else {
                    ev.stopPropagation();
                }
            })
        }
    )
}


const closePicker = (languagePickerOptions, languagePickerHead) => {
    $(languagePickerOptions).hide();
    const angleDown = $(languagePickerHead).find(".app-icon__angle-down")
    animateIconDown(angleDown);
}

const openPicker = (languagePickerOptions, languagePickerHead) => {
    $(languagePickerOptions).show();
    const angleDown = $(languagePickerHead).find(".app-icon__angle-down")
    animateIconUp(angleDown)
}

const togglePicker = (languagePickerOptions, languagePickerHead) => {
    if ($(languagePickerOptions).is(":visible")) {
        closePicker(languagePickerOptions, languagePickerHead)
    } else {
        openPicker(languagePickerOptions, languagePickerHead)
    }
}

const setPickerHeaderText = (text, selector) => {
    $(selector).text(text)
}

const onChangeLanguage = (optionElement) => {
    const url = optionElement.data('url');
    if (url) window.location.href = url;
}