import $, { event } from "jquery";
import { documentReady, elementReady } from "../ReadyListener";
import Cookies from 'js-cookie'
import { executeAfterLCP } from "../helpers/lcp";

const COOKIE_SESSION_START="vs";
const COOKIE_SESSION_DURATION_CONVERSION_SENT="vsd";
const COOKIE_DESKTOP_CONVERSION_SENT="vsdsk";
const COOKIE_PROMISING_BROWSER_CONTEXT_CONVERSION_SENT="vsdpr";

window.googleAdsQueue = [];
window.isGoogleAdsInitialized = false;
window.googleAnalyticsQueue = [];
window.isGoogleAnalyticsInitialized = false;

window.googleAdsLoaded = false
window.googleAnalyticsLoaded = false

function gtag() { 
    window.dataLayer = window.dataLayer || [];
    dataLayer.push(arguments); 
}

export function loadGoogleAds() {
    if(window.googleAdsLoaded) {
        return;
    }

    const code = `
    <!-- Google tag (gtag.js) -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11030556361"></script>
    <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'AW-11030556361');
    </script>
    `;

    $(document.body).append(code);

    window.googleAdsLoaded = true;
}

export function loadGoogleAnalytics() {
    if(window.googleAnalyticsLoaded) {
        return;
    }

    const code = `
    <!-- Google tag (gtag.js) -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-QQH4JF0V4M"></script>
    <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-QQH4JF0V4M');
    </script>
    `;

    $(document.body).append(code);

    window.googleAnalyticsLoaded = true;
}


export function loadMouseFlow() {
    window._mfq = window._mfq || [];
    (function() {
        var mf = document.createElement("script");
        mf.type = "text/javascript"; mf.defer = true;
        mf.src = "//cdn.mouseflow.com/projects/eb564d14-fb2a-4c9d-9926-2349e81a0175.js";
        document.getElementsByTagName("head")[0].appendChild(mf);
    })();
}


export function initGoogle({initAds, initAnalytics}) {
    executeAfterLCP(()=>{
        window.initAds = initAds;
        window.initAnalytics = initAnalytics;
    
        sendConsent(initAds, initAnalytics);
    
        if(initAds) {
            loadGoogleAds();
            sendConversion("initAdsOptin");
        }
    
        if(initAnalytics) {
            loadGoogleAnalytics();
            loadMouseFlow();
            sendConversion("initAnalyticsOptin");
        }
    
    
        initMehrAlsXSekundenTracking();
        initDesktopClientTracking();
        initPromisingBrowserContextTracking();
        setInterval(initMehrAlsXSekundenTracking, 1000*10);
    },5000)

        //setInterval(initDesktopClientTracking, 1000*2);
        //setInterval(initPromisingBrowserContextTracking, 1000*2);,
        
}

function hasSessionDurationConversionBeenSent() {
    let session_duration_conversion_sent = Cookies.get(COOKIE_SESSION_DURATION_CONVERSION_SENT, { domain: window.appConfig.COOKIE_DOMAIN });
    return !!session_duration_conversion_sent;
}

export function initMehrAlsXSekundenTracking()  {
    /* Session Dauer tracken für X-Sekunden Conversion */
    let session_start = Cookies.get(COOKIE_SESSION_START, { domain: window.appConfig.COOKIE_DOMAIN });
    let session_duration_conversion_sent = hasSessionDurationConversionBeenSent();
    if(!session_start) {
        Cookies.set(COOKIE_SESSION_START, new Date().getTime(), { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
    } else {
        let session_age = new Date().getTime() - session_start;
        if(!session_duration_conversion_sent && session_age > 30) {
            Cookies.set(COOKIE_SESSION_DURATION_CONVERSION_SENT, '1', { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
            sendConversionMehrAlsXSekundenAktiv();
        }
    }
}


export function initDesktopClientTracking()  {
    let isDesktopClient = isDesktopUser();
    let conv_sent = Cookies.get(COOKIE_DESKTOP_CONVERSION_SENT, { domain: window.appConfig.COOKIE_DOMAIN });
    if(!conv_sent && isDesktopClient) {
        Cookies.set(COOKIE_DESKTOP_CONVERSION_SENT, '1', { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
        sendConversionDesktopClient();
    }
}

export function initPromisingBrowserContextTracking()  {
    const is_apple = isAppleUser();
    const is_edge = isEdgeUser();
    const has_features_disabled = hasFeaturesDisabled();

    const isPromisingBrowserContext = is_apple || (is_edge && has_features_disabled);
    
    let conv_sent = Cookies.get(COOKIE_PROMISING_BROWSER_CONTEXT_CONVERSION_SENT, { domain: window.appConfig.COOKIE_DOMAIN });
    if(!conv_sent && isPromisingBrowserContext) {
        Cookies.set(COOKIE_PROMISING_BROWSER_CONTEXT_CONVERSION_SENT, '1', { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
        sendPromisingBrowserContext();
    }
}

const mapping = {
    "act_CaseStudyFormAbgeschickt": "umoECK_d5tsZEMnd44sp",
    "act_CaseStudyFormGeoeffnet": "Hp8LCLLd5tsZEMnd44sp",
    "act_KontaktformularAbgeschickt": "9UEECLXd5tsZEMnd44sp",
    "act_LeistungsuebersichtAngefordert": "oxZ3CLjd5tsZEMnd44sp",
    "act_MehrAlsXSekundenAktiv": "IBfyCLvd5tsZEMnd44sp",
    "act_NewsletterAngemeldet": "I4NuCL7d5tsZEMnd44sp",
    "act_RueckrufAngefordert": "e-7LCMHd5tsZEMnd44sp",
    "act_TerminanfrageGeklickt": "gYqCCPm-6dwZEMnd44sp",
    "act_SaaSOrderStart": "RZ5OCP696dwZEMnd44sp",
    "act_SaaSOrderSuccess": "th3yCJPA79sZEMnd44sp",
    "act_DesktopClient": "hnfaCIb6x9wZEMnd44sp",
    "act_PromisingBrowserContext": "h5GCCOCb3twZEMnd44sp"
}


function sendConversion(name, callback) {
    const completeName = "act_" + name;

    // Function to create a promise for sending an event to Google Ads
    function sendEventToAds() {
        return new Promise((resolve, reject) => {
            const adsMappingId = mapping[completeName];

            if (!adsMappingId) {
                //console.error(`No mapping found for ${completeName} in Google Ads`);
                resolve(); // Resolve anyway to not block the callback
                return;
            }

            gtag('event', 'conversion', {
                'send_to': "AW-11030556361/" + adsMappingId,
                'event_callback': function() {
                    resolve(); // Resolve the promise when the event is sent
                },
                'event_timeout': 2000  // Optional timeout if the event takes too long
            });
        });
    }

    // Function to create a promise for sending an event to Google Analytics
    function sendEventToAnalytics() {
        return new Promise((resolve, reject) => {
            gtag('event', completeName, {   // Custom event name
                'event_callback': function() {
                    resolve(); // Resolve the promise when the event is sent
                },
                'event_timeout': 2000  // Optional timeout
            });
        });
    }

    // Use Promise.all to wait for both events to complete
    Promise.all([sendEventToAds(), sendEventToAnalytics()])
        .then(() => {
            if (callback) {
                callback();
            }
        })
        .catch((e) => {
            console.error("Error in sending conversion events:", e);
            if (callback) {
                callback();
            }
        });
}


function sendConsent(ads, analytics) {
    gtag('consent', 'default', {
        'ad_user_data': ads ? 'granted' : 'denied',
        'ad_personalization': ads ? 'granted' : 'denied',
        'ad_storage': ads ? 'granted' : 'denied',
        'analytics_storage': analytics ? 'granted' : 'denied'
    });
}

export const sendConversionNewsletterAngemeldet = (callback)=>sendConversion("NewsletterAngemeldet", callback);
export const sendConversionKontaktformularAbgeschickt = (callback)=>sendConversion("KontaktformularAbgeschickt", callback);
export const sendConversionTerminanfrageGeklickt = (callback)=>sendConversion("TerminanfrageGeklickt", callback);
export const sendConversionMehrAlsXSekundenAktiv = (callback)=>sendConversion("MehrAlsXSekundenAktiv", callback);
export const sendConversionCaseStudyFormGeoeffnet = (callback)=>sendConversion("CaseStudyFormGeoeffnet", callback);
export const sendConversionCaseStudyFormAbgeschickt = (callback)=>sendConversion("CaseStudyFormAbgeschickt", callback);
export const sendLeistungsuebersichtAngefordert = (callback)=>sendConversion("LeistungsuebersichtAngefordert", callback);
export const sendRueckrufAngefordert = (callback)=>sendConversion("RueckrufAngefordert", callback);
export const sendSaaSOrderStart = (callback)=>sendConversion("SaaSOrderStart", callback);
export const sendSaaSOrderSuccess = (callback)=>sendConversion("SaaSOrderSuccess", callback);
export const sendConversionDesktopClient = (callback)=>sendConversion("DesktopClient", callback);
export const sendPromisingBrowserContext = (callback)=>sendConversion("PromisingBrowserContext", callback);

elementReady((root)=>{
    const hints = root.findWithRoot('[data-conversion-hint]').filter(":visible");
    hints.each((i,el)=>{
        if($(el).data("conversion-hint")=="newsletter_success") {
            sendConversionNewsletterAngemeldet();
        }
        if($(el).data("conversion-hint")=="contact_success") {
            sendConversionKontaktformularAbgeschickt();
        }
        if($(el).data("conversion-hint")=="case_study_form") {
            sendConversionCaseStudyFormGeoeffnet();
        }
        if($(el).data("conversion-hint")=="case_study_success") {
            sendConversionCaseStudyFormAbgeschickt();
        }
        if($(el).data("conversion-hint")=="leistungsuebersicht_angefordert") {
            sendLeistungsuebersichtAngefordert();
        }
        if($(el).data("conversion-hint")=="rueckruf_angefordert") {
            sendRueckrufAngefordert();
        }
        if($(el).data("conversion-hint")=="saas_order_start") {
            sendSaaSOrderStart();
        }
        if($(el).data("conversion-hint")=="saas_order_success") {
            sendSaaSOrderSuccess();
        }
        
    })
})

documentReady((root)=>{
    $("body").on('click', '[data-onclick-google-ads-hint]', function(ev) {
        const hint = $(this).data("onclick-google-ads-hint");

        ev.preventDefault();
        ev.stopPropagation();
        
        const continue_click = ()=>{
            setTimeout(()=>{
                window.location.href = $(this).attr("href");
            },500)
        }
        const force_continue_click = ()=>{
            setTimeout(()=>{
                window.location.href = $(this).attr("href");
            },1000)
        }
        force_continue_click(); // Falls GTAG nicht initialisiert ist oder geblockt wird, wollen wir trotzdem weitermachen....

        if(hint == "booking") {
            sendConversionTerminanfrageGeklickt(()=>{continue_click();});
        } else {
            continue_click();
        }

        return false;
    });

});

function isEdgeUser() {
    const isEdgeUser = /Edg/.test(navigator.userAgent);
    //console.log("isEdgeUser", isEdgeUser);
    return isEdgeUser;
}

function isAppleUser() {
    
    var platform = navigator.platform || 'unknown';
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Überprüfung auf iOS-Geräte
    var isiOS = /iPad|iPhone|iPod/.test(userAgent) ||
                (platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    // Überprüfung auf macOS-Geräte
    var isMacOS = platform === 'MacIntel' && navigator.maxTouchPoints === 0;

    const isAppleUser = isiOS || isMacOS;
    //console.log("isAppleUser", isAppleUser);
    return isAppleUser;
}

function hasFeaturesDisabled() {
    // Überprüfen, ob bestimmte JavaScript-Funktionen deaktiviert sind
    var featuresDisabled = false;
    
    // Überprüfung von Local Storage
    try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
    } catch (e) {
        featuresDisabled = true;
    }
    
    // Überprüfung von Session Storage
    try {
        sessionStorage.setItem('test', 'test');
        sessionStorage.removeItem('test');
    } catch (e) {
        featuresDisabled = true;
    }
    
    // Überprüfung von Cookies
    if (!navigator.cookieEnabled) {
        featuresDisabled = true;
    }
    
    // Überprüfung der Clipboard API
    if (!navigator.clipboard) {
        featuresDisabled = true;
    }
    
    // Überprüfung der WebGL-Unterstützung
    var hasWebGL = (function() {
        try {
        var canvas = document.createElement('canvas');
        return !!window.WebGLRenderingContext && (
            canvas.getContext('webgl') ||
            canvas.getContext('experimental-webgl')
        );
        } catch (e) {
        return false;
        }
    })();
    
    if (!hasWebGL) {
        featuresDisabled = true;
    }
    
    // Überprüfung der WebRTC-Unterstützung
    var hasWebRTC = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    
    if (!hasWebRTC) {
        featuresDisabled = true;
    }
    
    // Überprüfung der Geolocation API
    if (!('geolocation' in navigator)) {
        featuresDisabled = true;
    }
    
    // Überprüfung der Notifications API
    if (!('Notification' in window)) {
        featuresDisabled = true;
    }
    
    // Überprüfung der Battery Status API
    if (!('getBattery' in navigator)) {
        featuresDisabled = true;
    }
    
    // Überprüfung der Device Memory API
    if (!('deviceMemory' in navigator)) {
        featuresDisabled = true;
    }
    
    // Überprüfung der Network Information API
    if (!('connection' in navigator)) {
        featuresDisabled = true;
    }
    
    // Überprüfung von Media Queries mit matchMedia
    var prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    var prefersColorSchemeDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    
    // Wenn bestimmte Media Features nicht verfügbar sind
    if (typeof prefersReducedMotion !== 'boolean' || typeof prefersColorSchemeDark !== 'boolean') {
        featuresDisabled = true;
    }
    
    // Ergebnis zurückgeben
    //console.log("hasFeaturesDisabled", featuresDisabled);
    return featuresDisabled;
        
}

function isDesktopUser() {
    const isDesktopUser = window.matchMedia("(min-width: 769px)").matches;
    //console.log("isDesktopUser", isDesktopUser);
    return isDesktopUser;
}